import React from "react"
import SEO from "../seo"
import Hero from "../PC/organisms/Hero"
import Header from "../PC/organisms/Header"
import Footer from "../PC/organisms/Footer"
import styled from "styled-components"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import NewsContents from "../PC/organisms/NewsContents"
import judgePage from "../judgePage"
import MobileHero from "../mobile/organisms/MobileHero"
import MobileFooter from "../mobile/organisms/MobileFooter"
import MobileHeader from "../mobile/organisms/MobileHeader"
import MobileNewsContents from "../mobile/organisms/MobileNewsContents"

const Template = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    height: auto;
    width: 100%;
`
const NewsSEOData = {
    title: 'ニュース',
    lang: 'jp',
    url: '/news',
    description:'製造業、建設業、小売業などの労働集約型旧態産業において、デジタルトランスフォーメーションで産業に変革を起こすテクノロジー集団',
}

const NewsTemplate = ({ posts }) => {

    const page = judgePage('NEWS')

    const breakPoints = useBreakpoint()

    return (
        <Template>
            <SEO
                title={NewsSEOData.title}
                lang={NewsSEOData.lang}
                url={NewsSEOData.url}
                description={NewsSEOData.description}
            />
            {breakPoints.pc
                ?
                    <React.Fragment>
                        <Hero page={page.name} />
                        <NewsContents posts={posts} />
                        <Footer />
                        <Header page={page.name} />
                    </React.Fragment>
                :
                    null
            }
            {breakPoints.mobile
                ?
                    <React.Fragment>
                        <MobileHero page={page.name} />
                        <MobileNewsContents posts={posts} />
                        <MobileFooter />
                        <MobileHeader page={page.name} />
                    </React.Fragment>
                :
                    null
            }
        </Template>
    )
}

export default NewsTemplate