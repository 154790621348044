import React from "react"
import { graphql } from "gatsby"
import NewsTemplate from "../components/templates/NewsTemplate"
import "../layouts/style.css"

const NewsPage = ({ data }) => {
  const newsArticlePosts = []
  data.allMarkdownRemark.edges.forEach((post) => {
    const frontmatter = post.node.frontmatter
    const slug = frontmatter.slug.match(/([a-z]{4})(-)([0-9]*)/)
    const postData = {
      name: frontmatter.slug,
      id: (slug === null) ? -1 : slug[3],
      date: frontmatter.date,
      title: (frontmatter.title.length >= 41) ? `${frontmatter.title.slice(0, 40)}...` : frontmatter.title,
      thumbnailPath: frontmatter.thumbnail,
      tags: frontmatter.tags,
    }
    if (postData.date !== null) {
      newsArticlePosts.push(postData)
    }
  })
  newsArticlePosts.sort((a, b) => {
    if (a.date <= b.date) {
      return 1
    } else {
      return -1
    }
  })

  return (
    <NewsTemplate posts={newsArticlePosts} />
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      totalCount
      edges {
        node {
          id
          frontmatter {
            slug
            date
            title
            thumbnail
            tags
          }
        }
      }
    }
  }
`

export default NewsPage