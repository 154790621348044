import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import React from 'react'
import styled from "styled-components"
import NewsArticle from '../atoms/NewsArticle'

const Outer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 50px 0px 80px 0px;
`

const InnerWide = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 1205px;
    border-top: 1px solid #cdcdcd;
`

const Inner = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 904px;
    border-top: 1px solid #cdcdcd;
`

const NewsContents = ({ posts }) => {

    const breakPoints = useBreakpoint()

    return (
        <Outer>
            {breakPoints.wxga
                ?
                    <InnerWide>
                        {posts.map((post, idx) => {
                            const oldestArticle = idx + 1 === posts.length
                            const rightmostArticle = (idx) => {
                                const judge = (idx + 1) / 4
                                return Number.isInteger(judge)
                            }
                            const requireBorderRight = oldestArticle || rightmostArticle(idx)
                            return (
                                <NewsArticle
                                    name={post.name}
                                    date={post.date}
                                    title={post.title}
                                    thumbnailPath={post.thumbnailPath}
                                    tags={post.tags}
                                    key={post.id}
                                    requireBorderRight={requireBorderRight}
                                />
                        )})}
                    </InnerWide>
                :
                    <Inner>
                        {posts.map((post, idx) => {
                            const oldestArticle = idx + 1 === posts.length
                            const rightmostArticle = (idx) => {
                                const judge = (idx + 1) / 3
                                return Number.isInteger(judge)
                            }
                            const requireBorderRight = oldestArticle || rightmostArticle(idx)
                            return (
                                <NewsArticle
                                    name={post.name}
                                    date={post.date}
                                    title={post.title}
                                    thumbnailPath={post.thumbnailPath}
                                    tags={post.tags}
                                    key={post.id}
                                    requireBorderRight={requireBorderRight}
                                />
                        )})}
                    </Inner>
            }
        </Outer>
    )
}

export default NewsContents
