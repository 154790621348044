import React from 'react'
import styled from "styled-components"
import MobileNewsArticle from "../atoms/MobileNewsArticle"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 80px 0px;
`

const MobileNewsContents = ({ posts }) => {

    return (
        <Root>
            {posts.map((post, index) => (
                <MobileNewsArticle
                    date={post.date}
                    title={post.title}
                    thumbnailPath={post.thumbnailPath}
                    tags={post.tags}
                    name={post.name}
                    key={index}
                />
            ))}
        </Root>
    )
}

export default MobileNewsContents